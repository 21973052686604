@if (initialized()) {
  <ion-tabs class="page-portret" (ionTabsWillChange)="onTabsChange($event)">
    <ion-tab-bar slot="bottom">
      <ion-tab-button tab="ad6">
        <ion-icon name="home" />
        <ion-label>{{ 'tab.ad6' | translate }}</ion-label>
      </ion-tab-button>

      @if (eventSettings().isEventLive) {
        <ion-tab-button tab="live">
          <ion-icon name="bicycle" />
          <ion-label>{{ 'tab.live' | translate }}</ion-label>
          @if (unseenPassageCount() > 0) {
            <ion-badge color="danger ad6-bold">{{ unseenPassageCount() }}</ion-badge>
          }
        </ion-tab-button>
      }

      @if (eventSettings().isParticipantDataAvailable) {
        <ion-tab-button tab="favorites">
          <ion-icon name="people" />
          <ion-label>{{ 'tab.participants' | translate }}</ion-label>
        </ion-tab-button>
      }
      <ion-tab-button tab="info">
        <ion-icon name="ellipsis-vertical-circle" />
        <ion-label>{{ 'tab.info' | translate }}</ion-label>
        @if (unreadNewsNotifications().length > 0) {
          <ion-badge color="danger">{{ unreadNewsNotifications().length }}</ion-badge>
        }
      </ion-tab-button>
    </ion-tab-bar>
  </ion-tabs>
}
